.navbar {
    position: sticky;
    left: 0;
    top: 0;
    height: 50px;
    width: 100vw;
    border-right: solid 1px lightgray;
    background: #f5f5f5;
    box-sizing: border-box;
    z-index: 3;
    overflow: hidden;
    transition: width 0.3s ease-in-out;
    color: #000;
    font-weight: 700;
    touch-action: none;
}

@media screen and (min-width: 1200px) {
    .navbar {
        width: calc(100vw - 250px);
        left: 250px;
        padding: 0 15px;
    }
}

@media screen and (min-width: 1024px) {
    .navbar {
        left: 200px;
        width: calc(100vw - 200px);
    }
}

/*@media screen and (min-width: 840px) {*/
/*    .navbar {*/
/*        width: calc(100vw - 250px);*/
/*        padding: 0 25px;*/
/*    }*/
/*}*/

.navbar.share {
    left: 0;
    width: 100vw;
}

.navbar.closed {
    /* width: 100vw; */
}

.content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 5px 10px;
    overflow: hidden;
    font-size: 24px;
    text-align: center;
    box-sizing: border-box;
    border: none;
    font-weight: 700;
}

.content:disabled {
    pointer-events: none;
    display: block; /* For Firefox */
    color:black;
    font-weight: 700;
    opacity: 1
}

@media only screen and (max-width: 640px) {
    .content {
        width: 100%;
        font-size: 16px;
        text-align: left;
    }

    .navbar {
        overflow: scroll;
    }

    .content:disabled:before {
        font-size: 16px;
    }
}

.popup {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;
}
