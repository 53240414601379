.home {
    transition: padding 0.3s ease-in-out;
    touch-action: none;
    height: 100vh;
    overflow: hidden;
}
@media screen and (min-width: 1024px) {
    .home {
        padding-left: 200px;
        padding-bottom: 0;
    }
}
@media screen and (min-width: 1200px) {
    .home {
        padding-left: 250px;
        padding-bottom: 0;
    }
}

.home.share {
    padding-left: 0;
}

.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
}

.boardPopup {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
}
@media screen and (min-width: 641px) {
    .boardPopup {
        left: 200px;
        width: calc(100vw - 200px);
    }
}
@media screen and (min-width: 1201px) {
    .boardPopup {
        left: 250px;
    }
}

.popupWithClose {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    font-size: 20px;
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.close {
    position: absolute;
    top: -40px;
    right: -50px;
    width: 30px;
    height: 30px;
}

.link {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    font-size: 22px;
    color: #fff;
}

.undoButton {
    position: fixed;
    z-index: 10;
    bottom: 30px;
    left: 110px;
    width: 45px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: left 0.3s ease-in-out;
}

.undoButton.closed {
    left: 20px;
}

/*@media screen and (min-width: 575px) {*/
/*    .undoButton {*/
/*        width: 55px;*/
/*        left: 220px;*/
/*    }*/
/*}*/
@media screen and (min-width: 641px) {
    .undoButton {
        width: 55px;
        left: 270px;
    }
    .popupWithClose {
        font-size: 22px;
    }
}

[disabled] {
    opacity: 0.5;
}

.undoButton + .undoButton {
    transform: translateX(100%);
}
.modalBtn {
    border: none;
    outline: none;
    padding: 10px;
    min-width: 60px;
}
.capture {
    clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
    width: 150px;
    height: 135px;
    margin: 10px auto 15px;
}

.capture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.shareLink {
    color: #fff;
    pointer-events: all;
    text-align: center;
}

.popupContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.popupInput {
    width: 150px;
    outline: none;
    border: none;
    margin: 40px 0;
    padding: 5px;
    border: 1px solid grey;
}

.popupTitle {
    margin: 0;
    font-size: 14px;
}

.popupConfirm {
    outline: none;
    border: none;
    padding: 8px;
    cursor: pointer;
}

.popupText {
    font-size: 16px;
}

.PopupButtonsWrapper {
    display: flex;
    margin-top: 15px;
    flex-direction: row;
    justify-content: space-between;
}

.loaderText {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 24px;
    position: relative;
}
