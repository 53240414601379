.signup {
    padding: 15px 0;
}

@media (min-width: 768px) {
    .signup {
        padding: 50px 0;
    }
}

.text-center {
    margin-top: 0;
}

.form {
    text-align: left;
}

.control {
    margin: 0.25rem 0;
    flex-direction: column;
    position: relative;
    text-align: left;
}

.control label,
.control input {
    display: block;
    width: 100%;
}

.control label {
    font-weight: bold;
    flex: 1;
    color: #44546a;
    font-size: 13px;
    margin-bottom: 0.16em;
}

@media only screen and (min-width: 768px) {
    .control label {
        font-size: 20px;
    }
}

.control input {
    flex: 3;
    font: inherit;
    padding: 0;
    border: none;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
}

@media only screen and (min-width: 992px) {
    .control input {
        padding: 0.35rem 0;
    }
}

.control input:focus {
    outline: none;
    border-color: #4f005f;
    background: #f6dbfc;
}

.control.invalid input {
    border-color: red;
    background: #fbdada;
}

.message {
    color: red;
    font-size: 13px;
    margin: 0.25em 0;
    position: absolute;
    top: 100%;
}

.actions {
    text-align: center;
}

@media (min-width: 768px) {
    .control {
        align-items: center;
        flex-direction: row;
        margin: 1rem 0;
    }

    .message {
        font-size: 13px;
    }
}

.actions {
    text-align: left;
}

.btn {
    display: block;
    color: #44546a;
    margin-top: 15px;
    padding: 5px 15px;
    font-size: 15px;
    text-align: center;
    min-width: 150px;
    background-color: #fff;
    border: 2px solid #41719c;
    border-radius: 10px;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
}

@media only screen and (min-width: 768px) {
    .control label {
        font-size: 18px;
    }
}

.guestLogin {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
}
.guestTitle {
    color: #44546a;
    cursor: pointer;
    font-size: 18px;
    margin-bottom: 0.5em;
}

.guestMessage {
    color: #44546a;
    font-size: 14px;
    line-height: 1.3;
}

@media only screen and (min-width: 768px) {
    .guestMessage {
        font-size: 1em;
    }

    .guestTitle {
        font-size: 1.5em;
    }
}
.control input + .message {
    position: static;
}
.responseStatus {
    margin-top: 0;
    text-align: center;
    min-height: 19px;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.linkTitleSignup {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 10px;
    right: 30px;

}
@media screen and (max-width: 642px) {
    .linkTitleSignup {
        display: flex;
        justify-content: flex-end;
        position: static;
        margin-right: 20px;
       
    
    }
  

}

@media screen and  (min-width: 642px) and (max-width: 1024px){
    .linkTitleSignup {
        display: flex;
        justify-content: flex-end;
        position: fixed;
        bottom: 10px;
        right: 30px;
       
}
}

@media screen and (min-width: 500px) and (max-width: 1024px) and (orientation: landscape) {
    .linkTitleSignup{
        display: flex;
        justify-content: flex-end;
        position: static;
        margin-right: 20px;
    }
}



.linkTitleKeyboard{
    display: flex;
    justify-content: flex-end;
    position: static;
    margin-right: 20px;

}





