.links{
  width: 185px;
  display: flex;
  justify-content: flex-end;
}

.anchor{
  color: #44546a;
  text-decoration: none;
  font-size: 15px;
}
