.quote {
    max-width: 63vw;
    margin: 0 auto 40px;
    color: #44546A;
    text-align: center;
    font-weight: 400;
}

.intro {
    padding: 20px 0;
}

.intro h1 {
    font-size: 1em;
}

@media only screen and (min-width: 768px) {
   .intro h1 {
       font-size: 2em;
   }
}

.videoWrapper {
    max-width: 95vw;
    margin: 0 auto;
    position: relative;
    /*padding-bottom: 56.25%;*/
}

@media only screen and (min-width: 768px) {
    .videoWrapper {
        max-width: 55vw;
    }
}

.video {
    pointer-events: none;
}

.videoWrapper .video-react-control-bar {
    display: none !important;
}


.linkTitle{
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 10px;
    right: 30px;
}
@media screen and (max-width: 320px){
    .linkTitle{
        display: flex;
        justify-content: flex-end;
        position: static;
        margin-right: 20px;
    }
} 


@media screen and (min-width: 500px) and (max-width: 1023px) and (orientation: landscape) {
    .linkTitle{
        display: flex;
        justify-content: flex-end;
        position: relative;
        Top: 5px;
        right: 30px;
    
    }
}