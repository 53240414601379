.image {
    height: 60px;
    width: 70px;
    margin: 0 auto 5px;
    display: block;
    clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
    object-fit: fill;
}

.tooltip {
    display: block;
    border: none;
    max-width: calc(100% + 10px);
    text-overflow: ellipsis;
    outline: none;
    resize: none;
    margin: 0 -5px;
    background-color: #f5f5f5;
    font-size: 12px;
    height: 20px;
}

@media only screen and (min-width: 1024px) {
    .tooltip {
        max-width: calc(100% + 30px);
        margin: 5px -15px;
        background-color: #fff;
    }
}

.button {
    text-align: center;
}

.picker {
    margin: 0 30px;
    flex-shrink: 0;
    width: 80px;
    position: relative;
}

.delete {
    position: absolute;
    top: 0;
    right: 10px;
    transform: translate(100%, -50%);
    font-size: 20px;
    z-index: 3;
    cursor: pointer;
}

@media only screen and (max-width: 1024px) {
    .delete {
        top: 5px;
    }
}

.first {
    order: 0;
}

.last {
    order: 1;
}
