.timeline {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 20;
    background-color: #f5f5f5;
    padding-top: 35px;
}

@media only screen and (min-width: 1024px) {
    .timeline {
        bottom: -30px;
        right: 20px;
        left: 210px;
        background-color: transparent;
        padding-top: 0;
    }
}

@media only screen and (min-width: 1200px) {
    .timeline {
        left: 260px;
    }
}

.line {
    position: absolute;
    left: 0;
    right: 0;
    height: 3px;
    object-fit: cover;
    transform: translateY(-50%);
    top: 50%;
    width: 100%;
}

@media only screen and (min-width: 641px) {
    .timeline:after {
        bottom: 50%;
    }
}

.pointsWrapper {
    position: relative;
    overflow-x: auto;
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-width: 100%;
}

@media only screen and (min-width: 1024px) {
    .pointsWrapper {
        padding: 100px 0;
        transform: translateY(70px);
    }
}

.start img {
    width: 50px;
}
.stop img {
    width: 50px;
}

.start:hover,
.stop:hover {
    opacity: 0.5;
    cursor: pointer;
}

@media only screen and (max-width: 1023px) and (orientation: portrait) {
    .start,
    .stop {
        flex-shrink: 0;
        width: 30px;
        height: 26px;
        left: 10px;
    }
    .start img {
        width: 30px;
    }
    .stop img {
        width: 30px;
    }
    .stop {
        left: 70px;
    }
}

.timelineNav {
    position: absolute;
    left: 0;
    background-color: #f5f5f5;
    top: 0;
    display: flex;
    align-items: center;
    width: 100px;
    padding: 7px;
    justify-content: space-between;
}

@media only screen and (min-width: 500px) and (orientation: landscape) {
    .timelineNav {
        position: fixed;
        left: 0;
        bottom: 0;
        top: auto;
        background-color: #ffffff;
        padding: 20px;
        display: flex;
        width: 200px;
    }
}

@media only screen and (min-width: 1200px) {
    .timelineNav {
        width: 250px;
    }
}

.image.active {
    opacity: 0.5;
}

.exitBtn {
    position: absolute;
    left: 100px;
    background-color: #f5f5f5;
    top: 0;
    display: flex;
    align-items: center;
    width: 130px;
    padding: 7px;
}

.exitBtn img {
    width: 30px;
    height: 26px;
    cursor: pointer;
}

@media only screen and (min-width: 1024px) {
    .exitBtn {
        position: fixed;
        left: 0;
        bottom: 80px;
        top: auto;
        background-color: #ffffff;
        padding: 20px;
        display: flex;
        width: 200px;
    }
    .exitBtn img {
        padding: 5px 0px;
        margin-right: 5px;
        width: 50px;
        height: auto;
    }
}

@media only screen and (min-width: 1200px) {
    .exitBtn {
        bottom: 88px;
        width: 250px;
    }
}

@media screen and (min-width: 500px) and (max-width: 1023px) and (orientation: landscape) {
    .exitBtn {
        position: fixed;
        left: 0px;
        bottom: 44px;
        font-size: 12px;
        top: auto;
        background-color: #ffffff;
        padding: 5px;
        display: flex;
        width: 140px;
    }
    .exitBtn img {
        width: 40px;
        height: auto;
        margin-right: 5px;
    }
    .timelineNav {
        padding: 5px;
        width: 140px;
    }
    .stop img {
        width: 40px;
    }
    .start img {
        width: 40px;
    }
    .timeline {
        left: 140px;
        background-color: unset;
        bottom: 0;
    }
    .line {
        top: calc(50% + 35px);
    }
    .pointsWrapper {
        padding-top: 70px;
    }
}
