.closeButton {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -10%;
    top: 0;
    border: 1px solid gray;
    border-radius: 4px;
    font-size: 14px;
}

@media screen and (max-width: 1024px) {
    .closeButton {
        right: 0;
    }
}
