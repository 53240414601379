.button {
    width: 70px;
    height: 50px;
    position: absolute;
    left: -127px;
    transform: translateY(100%);
}

@media only screen and (max-width: 640px) {
    .button {
        left: -165px;
    }
}

@media only screen and (max-width: 640px) {
    .button {
        transform: translateY(-100%);
        flex-shrink: 0;
        left: 170px;
        width: 30px;
        height: 30px;
    }
}

.button {
    opacity: 0;
    padding: 5px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button .image {
    opacity: 1;
}

.button[disabled] .image {
    opacity: 0.5;
}