.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
}
.popupWithClose {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    font-size: 20px;
}

.content {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center;
    align-items: center;
}

.title {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
}

.message {
    margin: 10px 0;
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.close {
    position: absolute;
    top: -40px;
    right: -50px;
    width: 30px;
    height: 30px;
}

.popupButtonsWrapper {
    display: flex;
    margin-top: 15px;
    flex-direction: row;
    justify-content: space-between;
}

.modalBtn {
    border: none;
    outline: none;
    padding: 10px;
    width: 60px;
}

.capture {
    clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
    width: 150px;
    height: 135px;
    margin: 10px auto 15px;
}

.capture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
