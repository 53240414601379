.login {
    padding: 15px 0;
}

@media (min-width: 768px) {
    .login {
        padding: 50px 0;
    }
}

.form {
    text-align: left;
}

.control {
    margin: 0.25rem 0;
    flex-direction: column;
    position: relative;
    text-align: left;
}

.control label,
.control input {
    display: block;
    width: 100%;
}

.control label {
    font-weight: bold;
    flex: 1;
    color: #44546a;
    font-size: 15px;
    margin-bottom: 0.5rem;
}

@media only screen and (min-width: 768px) {
    .control label {
        font-size: 20px;
    }
}

.control input {
    flex: 3;
    font: inherit;
    padding: 0.35rem 0;
    /*border-radius: 6px;*/
    border: none;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
}

.control input:focus {
    outline: none;
    border-color: #4f005f;
    background: #f6dbfc;
}

.control.invalid input {
    border-color: red;
    background: #fbdada;
}

.message {
    color: red;
    font-size: 14px;
    margin: 0.25em 0;
}

.actions {
    text-align: center;
}

@media (min-width: 768px) {
    .control {
        align-items: center;
        flex-direction: row;
        margin: 1rem 0;
    }

    .message {
        font-size: 16px;
    }
}

.actions {
    text-align: left;
}

.btn {
    display: block;
    color: #44546a;
    margin-top: 15px;
    padding: 5px 15px;
    font-size: 15px;
    text-align: center;
    min-width: 150px;
    background-color: #fff;
    border: 2px solid #41719c;
    border-radius: 10px;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
}

.forgot {
    color: #44546a;
    font-weight: 700;
    margin-top: 5px;
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    .control label {
        font-size: 18px;
    }
}

.guestTitle {
    color: #44546a;
}

.guestMessage {
    color: #44546a;
}

.guestLogin {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
}
.overlay {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
}

.popupMessage {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    font-size: 22px;
    color: #fff;
}


.linkTitle{
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 10px;
    right: 30px;
}


.linkTitleKeyboard{
    display: flex;
    justify-content: flex-end;
    position: static;
    margin-right: 20px;

}
@media screen and (max-width: 320px){
    .linkTitle{
        display: flex;
        justify-content: flex-end;
        position: static;
        margin-right: 20px;
    }
} 

@media screen and (min-width: 500px) and (max-width: 1023px) and (orientation: landscape) {
    .linkTitle{
        display: flex;
        justify-content: flex-end;
        position: static;
        margin-right: 20px;
    }
}