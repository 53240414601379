.image {
    height: 70px;
    margin: 0 auto 5px;
    display: block;
    object-fit: contain;
}

.tooltip {
    display: block;
    font-size: 12px;
}

.button {
    text-align: center;
}

.picker {
    margin: 0 10px;
    flex-shrink: 0;
    width: 80px;
    order: 3;
}
