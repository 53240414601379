.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 100px;
    height: 100vh;
    background-color: #f2f2f2;
    box-sizing: border-box;
}

.activitySection {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 400px;
    background-color: #fff;
    width: 100%;
    padding: 20px;
}

.heading {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.heading input {
    /* background-color: #e0e0e0;
    color: #554e4e;
    border: none;
    padding: 5px;
    outline: none;
    border-radius: 5px;
    width: 200px;
    box-sizing: border-box; */
}

.startDate {
    left: 250px;
}

.endDate {
    left: 450px;
}

.datePicker {
    position: absolute;
    width: 175px;
}

.datePicker input {
    box-sizing: border-box;
    height: 38px;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    outline: none;
    padding: 2px 10px;
}

.heading input:focus {
    /* background-color: #c9c9c9 !important; */
}

.usernameInput {
    margin-right: 70px;
    width: 200px;
}

.row {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    width: 100%;
    border-top: 1px solid #dfdfdf;
    cursor: pointer;
}

.username {
    width: 40%;
}

.date {
    width: 20%;
}

.time {
    width: 20%;
}

.duration {
    width: 20%;
}

.content {
    width: 100%;
    margin-top: 50px;
}

.videoPopup {
    position: absolute;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #807b7bc9;
    overflow: hidden;
}

.loaderPopup {
    position: absolute;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #807b7bc9;
    overflow: hidden;
}

.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.pagination input[type='text'] {
    width: 40px;
    height: 30px;
    box-sizing: border-box;
    border: 1px solid gray;
    outline: none;
}

.pagination div:first-child {
    margin-right: 20px;
}

.pagination div {
    display: flex;
    align-items: center;
}

.pagination button {
    height: 30px;
    width: 35px;
    border: 1px solid gray;
    margin: 0 5px;
}

.close {
    position: absolute;
    right: 0;
}

.hexagonReport{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 20px;
    padding-bottom:20px;
}

.hexagonReportBtn{
    padding: 10px;
    margin-right: 10px;
}

.hexagonCountReportBtn{
    padding: 10px;

}

.reports{
    padding-right: 10px;
}

@media screen and (max-width:768px) {
    .hexagonReport{
        flex-direction: column;
        align-items:flex-start;
    }
    .hexagonReportBtn{
        margin-bottom: 10px;
    }
}

button {
    cursor: pointer;
}