.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
}
.popupWithClose {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    font-size: 20px;
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.close {
    position: absolute;
    top: -40px;
    right: -50px;
    width: 30px;
    height: 30px;
}

.popupButtonsWrapper {
    display: flex;
    margin-top: 15px;
    flex-direction: row;
    justify-content: space-between;
}

.modalBtn {
    border: none;
    outline: none;
    padding: 10px;
    min-width: 73px;
}

.message {
    font-size: 14px;
}

@media screen and (min-width: 1024px) {
    .message {
        font-size: 18px;
    }
}

.hexagon {
    clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
    position: relative;
    padding-bottom: 89%;
    width: 250px;
    font-size: 13px;
    background-color: #fff;
}

.box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 18%;
}
@media only screen and (min-width: 1024px) {
    .hexagon {
        width: 300px;
        font-size: 18px;
    }
    .box {
        padding: 50px 23%;
    }
}

.box:after {
    content: '';
    position: absolute;
    height: 97%;
    width: 100%;
    left: 0;
    top: 5px;
    background-size: contain;
    background-image: url(../../assets/hexagon-login.png);
    z-index: -1;
}

.hexagon .box:after {
    display: none;
}
