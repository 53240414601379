.header {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button {
    display: inline-block;
    color: #44546a;
    padding: 10px 25px;
    font-size: 22px;
    text-align: center;
    min-width: 150px;
    background-color: #fff;
    border: 2px solid #41719c;
    border-radius: 10px;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
.darkBtn{
    display: inline-block;
    color:#fff;
    padding: 10px 25px;
    font-size: 22px;
    text-align: center;
    min-width: 150px;
    background-color: #41719c;
    border: 2px solid #41719c;
    border-radius: 10px;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    margin-left: 30px;
    text-decoration: none;
}

.darkBtn:hover {
    background-color: #fff;
    border-color: #41719c;
    color: #41719c;
}


.button:not(:first-child) {
    margin-left: 30px;
}

@media only screen and (max-width: 768px) {
    .header {
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .button {
        margin-bottom: 10px;
    }
    .darkBtn{
        margin-bottom: 10px;
        margin-left: 0;
    }

    .button:not(:first-child) {
        margin-left: 0;
    }
}

.button:hover {
    background-color: #44546a;
    border-color: #44546a;
    color: #fff;
}

@media only screen and (min-width: 1024px) {
    .header {
        justify-content: flex-end;
        padding: 30px 30px;
    }
}


.linkTitle{
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 10px;
    right: 30px;
}

.linkTitleKeyboard{
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-right: 20px;

}

@media screen and (min-width: 500px) and (max-width: 1023px) and (orientation: landscape) {
    .linkTitle{
        display: flex;
        justify-content: flex-end;
        position: relative;
        margin-right: 20px;
    
    }
}