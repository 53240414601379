.point {
    width: 10px;
    height: 10px;
    position: relative;
    background-image: url('playback_dot.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.pointWrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    height: 40px;
    z-index: 1;
}

.golden-point {
    width: 30px;
    height: 30px;
    background-image: url('scrub-nugget.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.pointItem {
    position: relative;
    width: 100%;
    height: 100%;
}

.golden-point:before {
    /*content:'';*/
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
    position: absolute;
    background-color: yellow;
    z-index: -1;
}

.pointItem.active:after {
    content: '';
    width: 100vw;
    left: 0;
    top: 50%;
    transform: translate(-97%, -80%);
    position: absolute;
    z-index: 1;
    pointer-events: none;
    background-image: url('Playback_Scrubbar_ProgressLine.png');
    height: 9px;
    object-fit: contain;
}

.pointItem.active.last::after {
    content: '';
    width: 110vw;
    left: 0;
    top: 50%;
    transform: translate(-90%, -80%);
    position: absolute;
    z-index: 1;
    pointer-events: none;
    background-image: url('Playback_Scrubbar_ProgressLine.png');
    height: 9px;
    object-fit: contain;
}

.preview {
    width: 80px;
    height: 70px;
    object-fit: cover;
    clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in, visibility 0.3s ease-in;
    max-width: 80px;
}

.preview.show {
    opacity: 1;
    visibility: visible;
}

@media screen and (orientation: portrait) {
    .preview {
        display: none;
    }
}
