.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
}

.popupContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.popupWithClose {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    font-size: 20px;
}
.popupTitle {
    font-size: 14px;
}
.overlay {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.close {
    position: absolute;
    top: -40px;
    right: -50px;
    width: 30px;
    height: 30px;
}

.popupButtonsWrapper {
    display: flex;
    margin-top: 15px;
    flex-direction: row;
    justify-content: space-between;
}

.popupConfirm {
    border: none;
    outline: none;
    padding: 10px;
    margin-top: 30px;
    min-width: 60px;
}
