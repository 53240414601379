.hexagon {
    position: relative;
    grid-column: 1 / span 3;
    grid-row: calc(var(--counter) + var(--counter)) / span 2;
    height: 0;
    padding-bottom: 90%;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transform: scale(1);
}
.highlighted {
    opacity: 0.3;
}
.hoveredHexagon {
    z-index: 1;
}

.hexagon.selected:before {
    content: '';
    position: absolute;
    right: 25px;
    top: 8px;
    width: 6px;
    height: 6px;
    clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
    background-color: #666666;
    background-size: cover;
    z-index: 5;
}

.content {
    position: absolute;
    height: 100%;
    width: 100%;
    color: #111111;
    background-size: 98%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
    z-index: 1;
}

.box {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
}

.overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
    background-size: cover;
    background: #eceff9;
    z-index: 0;
    pointer-events: none;
}

/* .hexagon.active .overlay {
    background-image: url(../../../assets/texture.png);
} */

.hexagon.golden .box:before {
    position: absolute;
    content: '';
    clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
    background-image: url('golden_nugget.png');
    background-size: cover;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.textarea {
    position: absolute;
    inset: 0;
    font-size: 22px;
    opacity: 0;
}

.textcontent {
    position: absolute;
    inset: 0;
    width: 100%;
}

.textItemWrapper {
    text-align: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.textItem:empty {
    line-height: 8.8vw;
}
.textItem,
.popup {
    outline: none;
    border: none;
    width: 100%;
    position: relative;
    z-index: 1;
    line-height: 1;
    cursor: text;
    height: 100%;
}

.popup {
    font-size: 0.74vw;
    line-height: 1.1;
    background-color: #fff;
    color: #576afb;
    position: fixed;
    left: 50%;
    top: 50%;
    width: 250px;
    z-index: 2;
}

.shapeLeft,
.shapeRight {
    content: '';
    width: 23%;
    height: 100%;
    pointer-events: none;
}

.shapeLeft {
    float: left;
    clip-path: polygon(0 0, 100% 0, 0 50%, 100% 100%, 0 100%, 0 59%);
    shape-outside: polygon(0 0, 100% 0, 0 50%, 100% 100%, 0 100%, 0 59%);
}

.shapeRight {
    content: '';
    float: right;
    clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 100% 51%);
    shape-outside: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 100% 51%);
}

.textItemValue {
    outline: none;
    border: none;
    padding-top: 0;
    /* margin-top: 3.66vw; */
    overflow-wrap: anywhere;
    user-select: text;
    caret-color: transparent;
    padding: 0.75rem 0.5rem;
}
.breakall {
    word-break: break-all !important;
}

.anywhere {
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

.textItemValueIos {
    line-break: anywhere;
}

.box::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    background-size: cover;
    background-image: url(../../../assets/hex-border.png);
    background-repeat: no-repeat;
    z-index: 0;
}

.textItem {
    display: block;
    height: min-content;
    width: 100%;
    outline: none;
    border: none;
    font-weight: 300;
    direction: ltr;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.textItemValue.bold {
    font-weight: 700;
}

.textItemValue.italic {
    font-style: italic;
}

input {
    font-size: 16px;
}

.dragIconWrapper {
    cursor: pointer;
    position: absolute;
    top: 2px;
    left: 20px;
    z-index: 2;
}

.dragIcon {
    height: 16px;
}
