.hexagon {
    clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
    position: relative;
    padding-bottom: 89%;
}

.popup {
    background-color: #fff;
    width: 300px;
}

@media only screen and (max-width: 1024px) {
    .popup {
        width: 250px;
        font-size: 13px;
    }

    .popup.open {
        /*left: calc(50px + 50%);*/
    }
}

.box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 50px 25%;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.popup_box {
    height: 100%;
    padding: 20px 60px !important;
}

.box:after {
    content: '';
    position: absolute;
    height: 97%;
    width: 100%;
    left: 0;
    top: 5px;
    background-size: contain;
    background-image: url(../../../assets/hexagon-login.png);
    z-index: -1;
}

.popup .box:after {
    display: none;
}
