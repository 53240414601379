:root {
    --tab-dark: #e6e6e6;
    --tab-light: #f5f5f5;
}

body {
    overflow: hidden;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    direction: ltr;
}

html,
body {
    -webkit-overflow-scrolling: touch !important;
    overflow: auto !important;
    height: 100% !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px 0;
}

div {
    box-sizing: border-box;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.col-6 {
    width: 100%;
    padding: 0 15px;
}

@media only screen and (min-width: 641px) {
    .col-6 {
        width: 50%;
    }
}

.text-center {
    text-align: center;
}

.accordion {
    border-radius: 5px;
    border: 1px solid #d9d9d9;
}

.accordion .accordion-item:first-child .title {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.accordion .accordion-item:last-child .title {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.accordion .accordion-item .title {
    background-color: #f6f6f6;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.accordion .accordion-item .title:after {
    content: '\2795';
    font-size: 13px;
    color: #777;
    float: right;
    margin-left: 5px;
}

.accordion .accordion-item .panel {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
}

.accordion .accordion-item.active .title {
    background-color: #eee;
}

.accordion .accordion-item.active .title:after {
    content: '\2796';
}

.accordion .accordion-item.active .panel {
    max-height: 1000px;
    transition: max-height 0.25s ease-in;
}

.accordion {
    border-radius: 5px;
    border: 1px solid #d9d9d9;
}

.accordion .accordion-item:first-child .title {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.accordion .accordion-item:last-child .title {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.accordion .accordion-item .title {
    background-color: #f6f6f6;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
}

.accordion .accordion-item .title:after {
    content: '\02795';
    /* Unicode character for "plus" sign (+) */
    font-size: 13px;
    color: #777;
    float: right;
    margin-left: 5px;
}

.accordion .accordion-item .panel {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
}

.accordion .accordion-item.active .title {
    background-color: #eeeeee;
}

.accordion .accordion-item.active .title:after {
    content: '\2796';
    /* Unicode character for "minus" sign (-) */
}

.accordion .accordion-item.active .panel {
    max-height: 1000px;
    transition: max-height 0.25s ease-in;
}

[contenteditable] {
    direction: ltr;
    user-select: text;
}

.justify-center {
    justify-content: center;
}

.accordion-item.active .panel {
    padding: 20px 0;
}
.accordionSectionOpen {
    padding-bottom: 0 !important;
}
.accordion-item {
    position: relative;
}

section .accordion {
    border: none;
}

section .accordion .accordion-item .title {
    background-color: #f5f5f5;
}

.board {
    position: absolute;
    right: -10px;
    transform: translateX(100%);
    top: 56px;
    --amount: 4;
    --counter: 1;
    margin: 0;
    list-style-type: none;
    display: none;
    grid-template-columns: repeat(var(--amount), 1fr 2fr) 1fr;
    grid-gap: 0.2rem 0.4rem;
    width: 150px;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 5px 0px;
}

.boardAdd {
    position: absolute;
    right: -10px;
    transform: translateX(100%);
    top: 56px;
    margin: 0;
    list-style-type: none;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 15px 15px;
    align-items: flex-start;
    display: none;
}

.time {
    position: absolute;
    top: 56px;
    right: 0;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 0;
    transform: translateX(100%) scaleX(0);
}



.timeMobile{
    position: absolute;
    background-color: #fff;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    left: 0;
    right: 0;
    top: 0;
    }

    @media screen and (min-width: 500px) and (max-width: 1023px) and (orientation: landscape) {  
        .timeMobile{
        position: static;
    } 
}
    
    
    
.timeMobile.hideMobile {
        opacity: 0;
        transform: scaleX(0);
    }
    
    
@media only screen and (min-width: 641px) {
    .board {
        position: static;
        right: auto;
        transform: none;
        margin: 0 0 30px;
        width: 100%;
    }

    .boardAdd {
        position: static;
        right: auto;
        transform: none;
        margin: 0 0 30px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .time {
        position: static;
        width: 100%;
    }

    .accordion-item.active .time {
        opacity: 1;
        transform: scaleX(1);
    }
}

.accordion-item.active .time.hide {
    opacity: 0;
    transform: scaleX(0);
    max-height: 0;
    overflow: hidden;
    padding: 0;
}

.accordion-item.active .boardAdd {
    display: flex;
}

.accordion-item .panel {
    padding-top: 20px;
    padding-bottom: 90px;
}

@media only screen and (max-width: 641px) {
    .accordion-item.active .boardAdd {
        display: block;
    }
}

.accordion-item .soonMessage {
    display: none;
}

.accordion-item.active .soonMessage {
    display: block;
}

.accordion-item.active .board {
    display: grid;
}

@media only screen and (max-width: 641px) {
    .board {
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        transform: translateY(-100%);
        right: auto;
        top: 0;
        width: 100%;
    }
    .time {
        opacity: 1;
        transform: scaleX(1) translateY(-100%);
        left: 0;
        right: 0;
        top: 0;
    }

    .time.hide {
        opacity: 0;
        transform: scaleX(0);
    }

    .boardAdd {
        right: 0;
        top: 0;
        left: 0;
        transform: translateY(-100%);
        display: flex;
    }
}

section .accordion .accordion-item .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #43546a;
    font-weight: 700;
    font-size: 18px;
    border-bottom: 1px solid #e8e8e8;
    padding: 15px;
    margin-bottom: 5px;
}

.accordion {
    direction: ltr;
}

@media only screen and (max-width: 640px) {
    .hideFirstLevel .react-tabs__tab-panel--selected {
        /* transform: translateY(100%); */
    }

    .hideFirstLevel .board,
    .hideFirstLevel .boardAdd {
        transform: translateY(calc(-100% - 96px));
    }

    .time {
        transform: scaleX(1) translateY(-100%);
    }
}

.playback .accordion-item .panel {
    padding: 0;
}

/* Swiper css class Sidebar_iconWrapper__MWISB  */

.swiper {
    height: 100%;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.swiper-button-next {
    background-image: url(./assets/mobile/right_arrow.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    right: 0;
}

.swiper-button-next::after {
    display: none;
}

.swiper-button-prev {
    background-image: url(./assets/mobile/left_arrow.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    left: 0;
}

.swiper-button-prev::after {
    display: none;
}

@media screen and (min-width: 500px) and (orientation: landscape) {
    .swiper-button-prev {
        top: 15px !important;
        left: 50% !important;
        right: unset !important;
        transform: translateX(-50%) rotate(90deg);
    }
    .swiper-button-next {
        top: unset !important;
        bottom: 0 !important;
        right: 50% !important;
        transform: translateX(50%) rotate(90deg);
    }
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
    border-radius: 1rem;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #c3e8ff;
}

body {
    scrollbar-color: transparent #c3e8ff;
    scrollbar-width: 1rem;
}

.replayer-wrapper {
    background-color: #fff;
    transform: scale(0.6) !important;
}

.rr-player__frame {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
}
.sidebar-item-wrapper-active {
    background-color: #f5f5f5;
}

.sidebar-item-wrapper-active .sidebar-item-button img {
    max-width: 100%;
    opacity: 1;
    filter: drop-shadow(5px 5px 5px yellow);
}
.disabled-item {
    opacity: 0.3 !important;
}

.sidebar-item-image:touch {
    opacity: 1 !important;
}
@media screen and (min-width: 1024px) {
    .sidebar-item-wrapper {
        margin-bottom: 50px;
        cursor: pointer;
        width: unset;
    }
    .sidebar-item-tooltip {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 0;
        color: #fff;
        padding: 7px 10px;
        font-size: 16px;
        inline-size: 90px;
    }
    .sidebar-item-wrapper:hover .sidebar-item-tooltip {
        opacity: 1;
    }
    .sidebar-item-image:hover {
        opacity: 0.3;
    }
    .sidebar-item-button {
        transition: 0.4s ease-in-out;
        transition-property: opacity, filter;
        position: relative;
        width: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.sidebar-item-image {
    opacity: 1;
    height: auto;
    transition: 0.4s ease-in-out;
    transition-property: opacity, filter, -webkit-filter;
    max-width: 100%;
    max-height: 50px;
}

.sidebar-item-button {
    transition: 0.4s ease-in-out;
    transition-property: opacity, filter;
    position: relative;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.sidebar-item-tooltip {
    width: min-content;
    min-width: 80px;
    transition: opacity 0.3s ease-in-out;
    border-radius: 5px;
    text-align: center;
    line-height: 1;
    box-sizing: border-box;
    overflow-wrap: break-word;
    inline-size: 85px;
    font-size: 14px;
}

.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
}
.popupWithClose {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    font-size: 20px;
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.close {
    position: absolute;
    top: -40px;
    right: -50px;
    width: 30px;
    height: 30px;
}

.popupButtonsWrapper {
    display: flex;
    margin-top: 15px;
    flex-direction: row;
    justify-content: space-between;
}

.modalBtn {
    border: none;
    outline: none;
    padding: 10px;
    min-width: 60px;
}

.popup_message {
    font-size: 16px;
    display: inline-block;
    word-break: break-word;
    margin: 10px 0;
}

.popup_content {
    height: 100%;
}

.modalBtn_withMargin {
    margin-top: 10px;
}

.white {
    color: #fff;
}
