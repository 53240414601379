.button {
    margin: 0 5px;
    color: darkblue;
    font-weight: 700;
    max-width: 40px;
    cursor: pointer;
}

.timer {
    position: absolute;
    height: 80px;
    background: #fff;
    transform: translateY(-100%);
    z-index: 3;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    right:0;
}


.minimize {
    position: absolute;
    top: -5px;
    right: 5px;
    height: 20px;
}

@media screen and (min-width: 500px) and (max-width: 1023px) and (orientation: landscape) {
    .timer {
        height: 100%;
        flex-direction: column;
        right: -90px;
        transform: translateY(-96%);
    }
    .flexWrap {
        flex-direction: column;
        margin-bottom: 15px;
    }
    .button {
        margin-top: 5px;
    }
    .minimize {
        top: 8px;
    }
}

@media screen and (min-width: 1024px) {
    .timer {
        position: unset;
        height: unset;
        background: unset;
        transform: unset;
        align-items: unset;
        justify-content: unset;

        flex-wrap: wrap;
        text-align: center;
    }
    .timeLeft {
        margin: 10px auto 0;
    }
}

.timer input {
    text-align: right;
    max-width: 50px;
}

.flexWrap {
    display: flex;
    align-items: center;
}

.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
}

.stop {
    margin-top: 3px;
}

@media only screen and (max-width: 640px) {
    .popup {
        bottom: -83px;
        top: auto;
    }
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.close {
    position: absolute;
    top: -40px;
    right: -50px;
    width: 30px;
    height: 30px;
}

.link {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    color: #fff;
}

.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.close {
    position: absolute;
    top: -40px;
    right: -50px;
    width: 30px;
    height: 30px;
}

.link {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    color: #fff;
}

.image {
    opacity: 1;
    width: 100%;
    height: auto;
    transition: 0.4s ease-in-out;
    transition-property: opacity, filter, -webkit-filter;
    max-width: 100%;
}

