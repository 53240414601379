.sidebar {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 3;
    height: 100%;
    /* max-height: 100vh; */
    padding: 0 15px;
    box-sizing: border-box;
    background: #f5f5f5;
    overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

@media screen and (max-width: 1024px) {
    .hideMenu{
        display: none !important;
        
    }
}

@media screen and (min-width: 500px) and (orientation: landscape) {
    .hideMenu{
        display: flex !important;
        
    }


}



@media screen and (min-width: 1024px) {
    .sidebar {
        position: absolute;
        width: 200px;
    }
}

@media screen and (min-width: 1200px) {
    .sidebar {
        width: 250px;
    }
}

.logo {
    max-width: 100%;
    padding-top: 15px;
    padding-bottom: 20px;
    box-sizing: border-box;
}

.itemsWrapper {
    display: flex;
    flex-wrap: wrap;
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    justify-items: center;
    justify-content: center; */
}

.itemWrapper {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered {
    width: 100%;
}

.hidden {
    display: none !important;
}


.boardItemsWrapperUpper{
    width: 100%;
    max-height: 360px;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 15px 0;
    background-color: #ffffff;
    margin-bottom: 30px;

}

.boardItemsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.withMargin {
    /* margin-bottom: 50px; */
}

.rotated img {
    transform: scale(-1);
}

.mobileSidebar {
    display: flex;
    position: absolute;
    background-color: #e6e6e6;
    bottom: 0;
    top: unset;
    width: 100%;
    height: 80px;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 1;
}
.mobileSidebarContent {
    position: absolute;
    height: 90px;
    width: 100%;
    transform: translateY(-100%);
    left: 0;
    background-color: #fff;
}

.colorPickerWrapper {
    height: 60px;
}

@media screen and (min-width: 500px) and (orientation: landscape) {
    .mobileSidebar {
        height: 100%;
        min-height: 300px;
        z-index: 3;
        flex-direction: column;
        left: 0;
        top: 0;
        width: unset;
        padding: 20px 0;
    }
    .mobileSidebarContent {
        height: 100%;
        transform: translateX(90px);
        background-color: #f5f5f5;
        top: 0;
    }

    .colorPickerWrapper {
        height: 100%;
    }
}

.withPadding {
    padding: 5px;
}

.colorPickerMobile {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.3);
    /* transform: translateY(-150px); */
    height: 60px;
}

@media screen and (min-width: 500px) and (orientation: landscape) {
    .colorPickerMobile {
        position: absolute;
        top: 0;
        width: 80px;
        height: 100%;
    }
    .mobileSidebarPlaybackMode {
        display: none;
    }
}

.boardsListMobile {
    background-color: #f5f5f5;
    border: 1px solid rgba(0, 0, 0, 0.3);
    transform: translateY(-100%);
    height: 110px;
}

@media screen and (min-width: 500px) and (orientation: landscape) {
    .boardsListMobile {
        background-color: #f5f5f5;
        border: 1px solid rgba(0, 0, 0, 0.3);
        transform: translateY(-100%);
        height: 100%;
    }
}
